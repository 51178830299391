.container {
    width: 48%;
    height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.heading {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 20px;
    width: 100%;
}
.heading img {
    height: 15px;
    margin-right: 5px;
}

.form{
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
}
.form input, .form textarea {
    background-color: transparent;
    border: 1px solid red;
    width: calc(100% - 12px);
    font-family: Avenir, "Roboto", sans-serif;
    font-weight: bold;
    padding: 5px;
}
.form input::placeholder, .form textarea::placeholder {
    font-size: .7rem;
}
.form input{
    margin-bottom: 20px;
    height: 20px;
}
.form textarea {
    height: 100%;
    resize: none;
}
.form button {
    background-color: red;
    color: white;
    border: none;
    height: 30px;
    width: 80px;
    padding: 5px;
    margin-left: auto;
    margin-top: 5px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color .5s ease-out;
}
.form button:hover {
    background-color: #cf142b;
}

@media screen and (max-width: 700px) {
    .container {
       width: 100%;
        margin-bottom: 20px;
    }
}

