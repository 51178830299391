@import-normalize;

html {
    scroll-behavior: smooth;
}
body {
    font-family: Avenir, 'Roboto', sans-serif;
    font-weight: normal;
    margin: 0;
}
