.container {
    display: flex;
    z-index: 99;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: fixed;
    bottom: 20px;
    right: 20px;
}
.iconWrapper {
    background-color: #49e670;
    width: 35px;
    height: 35px;
    padding: 12px;
    border-radius: 50%;
    box-shadow: 0 5px 16px 0 rgba(0, 0, 0, 0.32);
    display: flex;
    align-items: center;
    justify-content: center;
}
.iconWrapper .icon {
    height: 100%;
    width: 100%;
    color: white;
}
