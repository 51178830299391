.container {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 30px;
    margin-bottom: 30px;
}

.left{
    width: calc(60% - 10px);
    margin-right: 10px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.right{
    width: 40%;
    display: flex;
    align-items: center;
}
.right img {
    width: 100%;
}

.title {
    margin: 0;
}
.pdf {
    display: inline-block;
    margin: 15px auto 30px;
    padding: 9px 27px;
    color: white;
    background-color: #cf142b;
    border: none;
    text-decoration: none;
    text-align: center;
    font-size: 0.9rem;
    font-weight: bold;
}
.left ul {
    padding-left: 15px;
    margin-top: 5px;
    margin-bottom: 5px;
}

.specs {
    font-size: .9rem;
    max-width: 70%;
    display: block;
}


@media screen and (max-width: 580px) {
    .left {
        width: 100%;
    }
    .right {
        width: 100%;
    }

    .specs {
        max-width: 100%;
    }
}
