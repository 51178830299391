.container {
    width: 242px;
    height: 360px;
    display: flex;
    align-items: center;
}
.wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    margin-bottom: 8px;
    margin-top: 0;
    font-weight: bold;
    font-size: 1.35rem;
}

.iconWrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 30px;
}
.iconWrapper.hasMap {
    justify-content: center;
}
.vline {
    margin-left: 15px;
    margin-right: 15px;
    height: 40px;
}
.avatar,
.map {
    height: 100px;
}
.avatar {
    border-radius: 50%;
}
.map {
    width: 100%;
}
@media screen and (max-width: 600px) {
    .map {
        width: 60%;
    }
}

.zone,
.locations {
    font-size: 0.8rem;
    line-height: 1.05;
}
.locations {
    margin-bottom: 20px;
    height: 26px;
}

.name {
    margin-bottom: 8px;
    margin-top: 0;
    font-weight: bold;
    font-size: 1.35rem;
}

.email,
.web {
    margin-bottom: 0;
    margin-top: 0;
    font-size: 0.7rem;
}
.email {
    margin-top: 10px;
}
.email img,
.web img {
    height: 8px;
    margin-right: 2px;
}
.email a,
.web a {
    color: black;
    text-decoration: none;
}
