.container {
    padding: 30px 10vw;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.newsletter {
    display: flex;
    flex-direction: column;
}
.newsletter h4 {
    margin: 0;
    font-weight: bold;
    font-size: 1rem;
}
.newsletter span {
    font-weight: bold;
    font-size: .8rem;
    margin-bottom: 10px;
    line-height: 1;
}

.form input {
    background-color: white;
    border: none;
    font-family: Avenir, "Roboto", sans-serif;
    font-weight: bold;
    padding: 5px;
    margin-bottom: 10px;
    height: 20px;
    width: 250px;
}
.form input::placeholder {
    color: black;
}
.form button {
    background-color: red;
    color: white;
    border: none;
    height: 30px;
    width: 80px;
    padding: 5px;
    margin-left: 10px;
    font-weight: bold;
    cursor: pointer;
}
.form button:hover {
    background-color: #cf142b;
}

.copyrightWrapper {
    font-size: .6rem;
    background-color: black;
    color: white;
    padding: 0 10vw 5px;
    display: flex;
    justify-content: space-between;
}
.copyrightWrapper p {
    margin: 0;
}
.copyright {

}
.asisehabla {
    font-weight: bold;
}
.asisehabla a {
    color: white;
    text-decoration: none;
}
.asisehabla a sup {
    font-size: .4rem;
}

.address {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}
.address img {
    height: 40px;
    width: 101px;
    filter: brightness(0) invert(1);
    margin-left: auto;
    margin-bottom: 13px;
}
.address span {
    font-size: .7rem;
    text-align: right;
    font-weight: bold;
    margin-bottom: 3px;
}
.address span a {
    color: white;
    text-decoration: none;
}


@media screen and (max-width: 700px) {
    .newsletter, .address {
        width: 100%;
        margin-bottom: 10px;
    }
}
