.container {
    padding: 20px 10vw;
    height: 200px;
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
}
.text {
    font-size: 1rem;
    margin: 0;
    text-align: center;
}
