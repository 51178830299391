:global .slick-dots {
    bottom: 25px;
    display: flex;
}
:global .slick-dots li div {
    align-items: center;
    justify-content: center;
    display: flex;
    color: gray;
    font-size: 1.3rem;
}
:global .slick-dots .active {
    display: none;
}
:global .slick-dots .slick-active .active {
    display: block;
}
:global .slick-dots .slick-active .normal {
    display: none;
}

.item {
    /*margin-top: -10px;*/
    height: 88.5vh;
    background-position-x: center;
    background-position-y: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    padding-left: 10vw;
    padding-top: 70px;
}

.wrapper {
    margin-top: 100px;
    width: 53%;
    z-index: 2;
    position: relative;
    padding: 40px 30px;
    color: white;
}
.heading {
    font-size: 1.3rem;
}
.title {
    font-size: 2.1rem;
    font-weight: bold;
    line-height: 1.4;
}
.subtitle {
    margin-top: 10px;
    font-size: 1.2rem;
    font-weight: bold;
}
.cta {
    margin-top: 40px;
    border: 1px white solid;
    padding: 5px 10px;
    margin-left: auto;
    display: table;
    text-decoration: none;
    color: white;
}
.cta:hover {
    background-color: white;
    color: black;
}

.overlay {
    position: absolute;
    top: 0;
    left: 0;
    background: linear-gradient(0deg, rgba(255, 255, 255, 0) 14%, rgba(255, 255, 255, 1) 100%);
    width: 100vw;
    height: 100%;
    z-index: 1;
}

@media screen and (max-width: 580px) {
    .item {
        padding-left: 5vw;
        padding-right: 5vw;
        display: flex;
        height: auto;
        min-height: 88vh;
    }
    .wrapper {
        width: 90%;
        padding-bottom: 20px;
        height: auto;
        display: table;
    }
}
