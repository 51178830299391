.container {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: .7rem;
    margin-bottom: 3px;
}

.icon {
    height: 12px;
    margin-right: 2px;
}
.cell .icon {
    margin-right: 7px;
}

.number {
    color: black;
    text-decoration: none;
    margin-left: 4px;
}
