.container {
    padding: 40px 10vw;
    display: flex;
    align-items: center;
}
.wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.title {
    font-size: 2rem;
    margin: 0;
}
.text {
    font-size: 1rem;
    margin: 0 0 20px;
}

.selectors {
    display: flex;
    position: relative;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.line {
    position: absolute;
    bottom: -15px;
    width: 110%;
    left: -4.5%;
}
