
.item {
    color: black;
    text-decoration: none;
    margin-left: 15px;
    font-weight: bold;
    transition: color .3s ease-out;
}
.item:hover {
    color: gray;
}
.item.active {
    color: red;
    border: 1px solid red;
    padding: 3px 7px;
}
.item.active:hover {
    color: white;
    background-color: red;
}
