.container {
    padding: 40px 10vw;
}

.heading {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}
.heading img{
    width: 8px;
    margin-right: 5px;
}
.heading h2{
    font-size: 1.7rem;
    font-weight: bold;
    margin: 0;
}
.heading h2 a{
    color: black;
    text-decoration: none;
}
.heading h5 {
    font-size: .8rem;
    font-weight: normal;
    margin: 0 0 0 13px;
}

.membersContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 4rem;
}

@media screen and (max-width: 900px) {
    .membersContainer {
        justify-content: space-around;
    }
}

