.container {
    padding: 20px 10vw;
    display: flex;
    align-items: center;
}
.container > div {
    width: 100%;
}
.title, .subtitle {
    width: 100%;
    text-align: center;
}
.title {
    font-size: 2rem;
    font-weight: bold;
    text-transform: uppercase;
    margin-bottom: 0;
    margin-top: 0;
}
.subtitle {
    font-size: 1rem;
    margin-top: 0;
    font-weight: normal;
}

.itemsWrapper {
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    align-items: flex-start;
    margin-top: 30px;
}
