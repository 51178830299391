.container {
    padding: 20px 10vw;
    display: flex;
    align-items: center;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    color: white;
     background-color: red;
}
.wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.title {
    font-size: 1.5rem;
    font-weight: bold;
    margin: 0;
    text-align: center;
}
.text {
    font-size: 1rem;
    margin: 0;
    text-align: center;
}
.quote {
    margin-top: 40px;
    margin-bottom: 40px;
    font-style: italic;
    text-align: center;
    max-width: 100%;
}

.iconsWrapper {
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon {
    margin: 10px 20px;
}
.icon.iso {
    width: 350px;
}
