.container {
    background: none;
    border: none;
    outline: none;
    cursor: pointer;
}
.iconWrapper {
    height: 60px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-bottom: 3px;
}
.icon {
    width: 50px;
}
.name {
    font-size: 1rem;
    font-weight: normal;
    margin: 0;
}
.active .name{
    color: red;
}
