.container {
    padding: 20px 10vw;
}

.container h2 {
    text-align: center;
    width: 100%;
    font-weight: bold;
    font-size: 2rem;
    margin-bottom: 0;
}

.container p {
    text-align: center;
    color: gray;
    font-size: 1.1rem;
}
