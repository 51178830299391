.container {
    padding: 10px 10vw;
    background-color: black;
    color: white;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.container p{
    margin-top: 0;
    margin-bottom: 0;
    font-size: 0.7rem;
    font-weight: bold;
}
.container p a {
    color: white;
    text-decoration: none;
    margin-right: 10px;
    margin-left: 3px
}
.container p a:hover {
    text-decoration: underline;
}

.icon {
    height: 15px;
    margin-right: 3px;
    margin-bottom: -3px;
}
.phone {
}
.location {
}
.mail {
}

