.container {
    height: 140px;
    width: 100vw;
    margin-top: 10px;
    display: flex;
    align-items: center;

    position: absolute;
    top: 20px;
    z-index: 1;
}

.logo {
    padding-left: 10vw;
    width: 250px;
    max-width: 100%;
}

.linkWrapper {
    margin-left: auto;
    padding-right: 10vw;
}
.mobileButton {
    margin-left: auto;
    margin-right: 10vw;
    display: none;
    background-color: transparent;
    border: 1px solid black;
    border-radius: 0;
    font-size: 1.5rem;
    padding: 5px;
    line-height: 1;
    cursor: pointer;
    transition: background-color .5s ease-out;
}
.mobileButton:hover {
    background-color: lightgray;
}
.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: black;
    overflow-x: hidden;
    transition: 0.5s ease-out;
    padding-top: 60px;
    display: flex;
    flex-direction: column;
}
.sidenav a {
    color: white;
    width: 200px;
    margin-left: 20px;
    margin-bottom: 10px;
}
.sidenav.open {
    width: 300px;
}

@media screen and (max-width: 1050px) {
    .linkWrapper {
        display: none;
    }
    .mobileLinkWrapper, .mobileButton {
        display: block;
    }
}

@media screen and (max-width: 748px) {
    .container {
        top: 35px;
    }
}

@media screen and (max-width: 454px) {
    .container {
        top: 50px;
    }
}
