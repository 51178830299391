.modalOverlay {
    display: flex;
    align-items: center;
    justify-content: center;

    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.75);
}

.modal {
    width: 80%;
    height: 80%;
    outline: none;
}

.closeButton {
    color: white;
    position: absolute;
    right: 10%;
    background-color: transparent;
    top: 6%;
    font-size: 1rem;
    border: none;
    cursor: pointer;
    transition: .2s color ease-out;
    outline: none;
}
.closeButton:hover {
    color: #cf142b;
}

