.container {
    padding: 30px 10vw;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
    background-color: #f1f1f1;
}

.left {
    width: 500px;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-between;
}
.right {
    width: 50%;
    display: flex;
    align-items: center;
}

.admin {
    width: 57%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.title {
    margin-bottom: 10px;
    margin-top: 0;
    font-weight: bold;
    font-size: 1.35rem;
}

.email {
    margin-top: 0;
    margin-bottom: 10px;
    font-size: 0.7rem;
    font-weight: bold;
}
.email img {
    height: 10px;
    margin-right: 2px;
}
.email a {
    color: black;
    text-decoration: none;
}

.rrss {
}
.rrss .icon {
    width: 12px;
    margin-right: 3px;
}
.rrss a {
    color: black;
    text-decoration: none;
    font-size: 0.8rem;
}

.buttons {
    margin-top: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 300px;
}
.buttons button {
    padding: 9px 20px;
    color: white;
    background-color: red;
    border: none;
    text-decoration: none;
    font-size: 0.8rem;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.5s ease-out;
}
.buttons button:hover {
    background-color: #cf142b;
}

.despachosWrapper {
    display: flex;
    flex-direction: column;
}
.despachos {
    font-size: 1rem;
    margin-top: 15px;
    font-weight: bold;
    color: red;
}
.entregas {
    font-size: 0.8rem;
    margin-bottom: 10px;
}

@media screen and (max-width: 1249px) {
    .admin {
        margin-left: 0;
        width: 100%;
    }
}
@media screen and (max-width: 600px) {
    .left,
    .right {
        justify-content: space-around;
        width: 100%;
    }
}

.entregasImg,
.cuentasImg {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
.entregasImg {
    background-image: url('/img/contact/SectorInformatico_Logistica.jpg');
}
.cuentasImg {
    background-image: url('/img/contact/SectorInformatico_CuentasBancarias.jpg');
}
