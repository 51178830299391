.container {
    padding: 50px 10vw;
    display: flex;
    align-items: center;
    background-color: black;
    color: white;
}
.container p {
    width: 100%;
    text-align: center;
    color: white;
    font-weight: normal;
}
