.container {
    width: 48%;
    height: 400px;
    display: flex;
    align-items: center;
    flex-direction: column;
}
.heading {
    font-weight: bold;
    font-size: 1.5rem;
    margin-top: 0;
    margin-bottom: 20px;
    width: 100%;
}
.heading img {
    height: 15px;
    margin-right: 5px;
}
.heading .address {
    margin-left: 5px;
    font-weight: normal;
    font-size: .8rem;
    color: black;
    text-decoration: none;
}

.googleMap {
    width: 100%;
    height: 100%;
}

@media screen and (max-width: 700px) {
    .container {
        width: 100%;
    }
}
